import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import useStaticData from 'src/hooks/data/use-static-data'
import configuration from '~/configuration'
import { IPromiseSearchOption } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { ISelectOption } from '~/core/ui/Select'
import QueryCountryStates from '~/lib/graphql/query-country-states'
import QueryTagsList from '../../settings/tags/graphql/query-tags-list'
import { ITags } from '../../settings/tags/types'
import { QueryProfileSourceList } from '../graphql/query-profile-source-list'
import QuerySkillsList from '../graphql/query-skills-list'
import QueryTalentPoolList from '../graphql/query-talent-pool-list'
import {
  IPromiseSourceListOption,
  SkillsType,
  SourceListType,
  TalentPoolType
} from '../types'

const useCandidateProfile = ({ sourced = '', disabledOption = [''] }) => {
  const { i18n } = useTranslation()
  const { clientGraphQL } = useContextGraphQL()
  const profileSourced = useEnumsData({
    enumType: 'ProfileSourced',
    locale: i18n.language
  })
  const currencySalary = useEnumsData({
    enumType: 'JobCurrency',
    locale: i18n.language
  })
  const profileLevel = useEnumsData({
    enumType: 'ProfileProfileLevel',
    locale: i18n.language
  })

  const promiseCountryStateOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryCountryStates, { ...params, stateOnly: true })
        .toPromise()
        .then((result: IResponseContextResult<{ fullName?: string }>) => {
          if (result.error) {
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { countryStates } = result?.data
          const collection = countryStates?.collection || []
          const metadata = countryStates?.metadata || {}

          const cloneData = collection.map(
            (item: { fullName?: string; id?: string }) => {
              return {
                id: item.id,
                value: item.fullName,
                supportingObj: {
                  name: item.fullName
                }
              }
            }
          )

          return resolve({ metadata, collection: cloneData })
        })
    })

  const promiseSkillsOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QuerySkillsList, params)
        .toPromise()
        .then((result: IResponseContextResult<SkillsType>) => {
          if (result.error) {
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { skillsList } = result?.data
          const collection = skillsList?.collection || []
          const metadata = skillsList?.metadata || {}

          const cloneData = collection.map((item: SkillsType) => {
            return {
              value: item.name,
              supportingObj: {
                name: item.name
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  const promiseTalentPoolOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryTalentPoolList, params)
        .toPromise()
        .then((result: IResponseContextResult<TalentPoolType>) => {
          if (result.error) {
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { talentPoolsList } = result?.data
          const collection = talentPoolsList?.collection || []
          const metadata = talentPoolsList?.metadata || {}

          const cloneData: ISelectOption[] = collection.map(
            (item: TalentPoolType) => {
              return {
                value: item.id,
                tooltipDisabled: 'Can not remove a current talent pool',
                supportingObj: {
                  name: item.name
                },
                ...(disabledOption && item.id
                  ? {
                      disabled: disabledOption.includes(item.id.toString())
                    }
                  : {})
              }
            }
          )

          return resolve({ metadata, collection: cloneData })
        })
    })

  const promiseTagsOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryTagsList, {
          ...params,
          limit: 50,
          sortByAlphabet: 'true',
          kind: 'profile'
        })
        .toPromise()
        .then((result: IResponseContextResult<ITags>) => {
          if (result.error) {
            resolve({
              metadata: {
                totalCount: configuration.defaultPageSize
              },
              collection: []
            })
          }

          const { tagsList } = result?.data
          const collection = tagsList?.collection || []
          const metadata = tagsList?.metadata || {}

          const cloneData = collection.map((item: ITags) => {
            return {
              label: item.value,
              value: item.id,
              supportingObj: {
                name: item.name
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  const promiseProfileSourceListOptions = useCallback<
    (
      params: IPromiseSourceListOption
    ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  >(
    (params) => {
      return sourced
        ? clientGraphQL
            .query(QueryProfileSourceList, { ...params, sourced })
            .toPromise()
            .then((result: IResponseContextResult<SourceListType>) => {
              if (result.error) {
                return { metadata: { totalCount: 0 }, collection: [] }
              }

              const { profileSourcesList } = result?.data
              const collection = profileSourcesList?.collection || []
              const metadata = profileSourcesList?.metadata || {}

              const cloneData = collection.map((item: SourceListType) => {
                return {
                  value: item.key,
                  supportingObj: {
                    name: item.name
                  }
                }
              })

              return { metadata, collection: cloneData }
            })
        : Promise.resolve({ metadata: { totalCount: 0 }, collection: [] })
    },
    [clientGraphQL, sourced]
  )

  const languageCollection = useStaticData({
    keyName: 'languages',
    locale: i18n.language
  })
  const languageList = languageCollection.map(
    (item: { language: string; code: string }) => {
      return {
        value: item.code,
        supportingObj: {
          name: item.language
        }
      }
    }
  )

  const languageProficienciesCollection = useStaticData({
    keyName: 'languageProficiencies',
    locale: i18n.language
  })
  const languageProficiencies = languageProficienciesCollection?.map(
    (item: { name: string; description: string }) => {
      return {
        value: String(item.name),
        description: item.description,
        supportingObj: {
          name: item.description || ''
        }
      }
    }
  )

  return {
    promiseCountryStateOptions,
    promiseSkillsOptions,
    promiseTagsOptions,
    promiseTalentPoolOptions,
    profileSourced,
    currencySalary,
    languageList,
    languageProficiencies,
    profileLevel,
    promiseProfileSourceListOptions
  }
}

export default useCandidateProfile
